import { FaqCategoryMenuQueryFragment } from 'graphqlTypes';
import { SideNavigationMenu } from 'page-components/shared/navigation-menus/SideNavigationMenu';
import React, { FC } from 'react';

interface FaqCategoryMenuLargeProps {
  data: FaqCategoryMenuQueryFragment;
  currentCategoryId?: string;
  onCategoryClick: (id: string) => void;
}

export const FaqCategoryMenuLarge: FC<FaqCategoryMenuLargeProps> = ({
  currentCategoryId,
  onCategoryClick,
  data,
}) => {
  const orderedCategories = data.allContentfulFaqCategory.nodes.map(
    (faqCategory) => ({
      id: faqCategory.id,
      heading: faqCategory.heading || '',
      isCurrentlySelected: faqCategory.id === currentCategoryId,
      onClick: () => onCategoryClick(faqCategory.id),
    }),
  );
  return <SideNavigationMenu title="Topics" items={orderedCategories} />;
};
