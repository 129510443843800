import { Grid } from 'common/layouts/grid/Grid';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Step } from './Step';

const StepsWrapper = styled(Flex)`
  max-width: 1140px;
  margin: auto;
  padding: 120px 40px;
`;

export const StepsLarge = () => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <StepsWrapper column>
      <ResponsiveHeading variant={2} marginBottom={32}>
        {heading}
      </ResponsiveHeading>
      <ResponsiveBody variant={1} marginBottom={80}>
        {body}
      </ResponsiveBody>
      <Grid cols={2} gap={6}>
        <Step stepNumber={1} />
        <Step stepNumber={2} />
        <Step stepNumber={3} />
        <Step stepNumber={4} />
      </Grid>
    </StepsWrapper>
  </ShowOn>
);
