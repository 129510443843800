import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  max-width: 400px;
`;

const stepCopy = {
  1: {
    heading: 'Meet your dedicated advisor.',
    body:
      "We'll review your savings, Social Security and other income sources, while gaining a greater understanding of your goals and needs.",
    image: <StaticImage src="../images/income-step-1.webp" alt="step1" />,
  },
  2: {
    heading: 'Create your customized plan.',
    body:
      "We'll work with you to create an investment strategy and spending plan that ensures your savings will last, while allowing you to spend with confidence.",
    image: <StaticImage src="../images/income-step-2.webp" alt="step1" />,
  },
  3: {
    heading: 'Establish your monthly income.',
    body:
      "Next, we'll simplify your savings into one or more Retirable IRAs where we can apply our retirement-specific tax strategies, investment allocations, and spending guidance.",
    image: <StaticImage src="../images/income-step-3.webp" alt="step1" />,
  },
  4: {
    heading: 'Spend with peace of mind.',
    body:
      "Need to make a withdrawal? We'll show you what's safe to take out and adjust your plan in real-time based on what you choose. And while you're busy living life, we'll make sure all your investments stay on track.",
    image: <StaticImage src="../images/income-step-4.webp" alt="step1" />,
  },
};

interface StepProps extends FlexProps {
  stepNumber: 1 | 2 | 3 | 4;
}

export const Step: FC<StepProps> = ({ stepNumber, ...rest }) => {
  const { heading, body, image } = stepCopy[stepNumber];

  return (
    <Flex column {...rest}>
      <ImageWrapper>{image}</ImageWrapper>
      <Subheading variant={1} marginBottom={12} marginTop={40}>
        {heading}
      </Subheading>
      <Body variant={3}>{body}</Body>
    </Flex>
  );
};
