import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { FaqPageQuery } from 'graphqlTypes';
import { HorizontalRule } from 'page-components/advice/horizontal-rule/HorizontalRule';
import {
  FaqCategoryMenuSmall,
  FaqLineItem,
  HeroLarge,
  HeroSmall,
} from 'page-components/faq';
import { FaqCategoryMenuLarge } from 'page-components/faq/faq-category-menu/FaqCategoryMenuLarge';
import { StepsLarge } from 'page-components/income/steps/StepsLarge';
import { StepsSmall } from 'page-components/income/steps/StepsSmall';
import {
  BottomCta,
  DesktopWrapper,
  MobileWrapper,
  TwoPanelNavigationWrapper,
} from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC, useState } from 'react';

interface FaqProps {
  data: FaqPageQuery;
}

const Faq: FC<FaqProps> = ({ data }) => {
  const faqCategoryIds = data.allContentfulFaqCategory.nodes.map(
    (category) => category.id,
  );

  const [selectedFaqCategory, setSelectedFaqCategory] = useState<string>(
    faqCategoryIds[0],
  );

  const currentCategory = data.allContentfulFaqCategory.edges.find(
    (category) => category.node.id === selectedFaqCategory,
  )?.node;

  const faqPosts = currentCategory?.faqpost || [];

  return (
    <Layout>
      <PageWrapper background={colors.greenLight}>
        <Navigation />
        <DesktopWrapper padding="0 40px" maxWidth={1140}>
          <HeroLarge />
          <TwoPanelNavigationWrapper
            rowGap="80px"
            columnGap="80px"
            navigationMenuWidth="30%"
            leftHandNavigationMenu={
              <FaqCategoryMenuLarge
                data={data}
                onCategoryClick={setSelectedFaqCategory}
                currentCategoryId={selectedFaqCategory}
              />
            }
          >
            {faqPosts.map((faq, idx) => (
              <>
                {idx !== 0 && <HorizontalRule />}
                {faq && <FaqLineItem faq={faq} key={faq.id} />}
              </>
            ))}
          </TwoPanelNavigationWrapper>
        </DesktopWrapper>
        <DesktopWrapper>
          <StepsLarge />
        </DesktopWrapper>
        <MobileWrapper>
          <HeroSmall />
          <FaqCategoryMenuSmall
            data={data}
            currentCategoryId={selectedFaqCategory}
            onCategoryClick={setSelectedFaqCategory}
          />
          {faqPosts.map((faq, idx) => (
            <>
              {idx !== 0 && <HorizontalRule />}
              {faq && <FaqLineItem faq={faq} key={faq.id} />}
            </>
          ))}
          <StepsSmall />
        </MobileWrapper>
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <BottomCta
          heading="Start getting your money ready for retirement."
          body="From greater peace of mind to ongoing care, we're ready to lend a hand."
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query FaqPage {
    ...FaqCategoryMenuQuery
    allContentfulFaqCategory(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          faqpost {
            ...FaqLineItem
            id
          }
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Retirable FAQs"
    description="Retirable is here to answer your frequently asked questions about retirement."
    imagePath="faq-featured.png"
  />
);
