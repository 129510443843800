import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const HeroWrapper = styled.div`
  padding: 56px 0 20px;
`;

export const HeroSmall = () => (
  <HeroWrapper>
    <Heading variant={4} marginBottom={16}>
      {heading}
    </Heading>
    <Body variant={2}>{body}</Body>
  </HeroWrapper>
);
