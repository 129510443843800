import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Step } from './Step';

const StepsWrapper = styled(Flex)`
  max-width: 1140px;
  margin: auto;
  padding: 80px 20px;
`;

export const StepsSmall = () => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <StepsWrapper column>
      <Heading variant={4} marginBottom={16}>
        {heading}
      </Heading>
      <Body variant={3} marginBottom={64}>
        {body}
      </Body>
      <Step stepNumber={1} marginBottom={80} />
      <Step stepNumber={2} marginBottom={80} />
      <Step stepNumber={3} marginBottom={80} />
      <Step stepNumber={4} marginBottom={8} />
    </StepsWrapper>
  </ShowOn>
);
