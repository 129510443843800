import { Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { FaqLineItemFragment } from 'graphqlTypes';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

export const FaqLineItemQuery = graphql`
  fragment FaqLineItem on ContentfulFaqPost {
    id
    questionHeading
    answerContent {
      childMdx {
        body
      }
    }
  }
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;

interface StyledIconWrapperProps {
  rotate: boolean;
}

const StyledIconWrapper = styled(Flex)<StyledIconWrapperProps>`
  min-height: 25px;
  min-width: 25px;
  background-color: ${colors.peach};
  border-radius: 50%;
  transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'unset')};
`;

interface FaqLineItemProps {
  faq: FaqLineItemFragment;
}

export const FaqLineItem: FC<FaqLineItemProps> = ({ faq }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <StyledFlex
      marginTop={32}
      marginBottom={32}
      column
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={isExpanded ? 12 : 0}
      >
        <Body variant={2} medium>
          {faq.questionHeading}
        </Body>
        <StyledIconWrapper
          alignItems="center"
          justifyContent="center"
          rotate={isExpanded}
          marginLeft={8}
        >
          <Icon name={IconName.CaretDown} />
        </StyledIconWrapper>
      </Flex>
      {isExpanded && (
        <Body variant={3}>
          {faq.answerContent?.childMdx?.body && (
            <MDXRenderer>{faq.answerContent.childMdx?.body}</MDXRenderer>
          )}
        </Body>
      )}
    </StyledFlex>
  );
};
