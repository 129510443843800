import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { device } from 'component-library/styles/device';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const BUSINESS_UNIT_ID = '60f852fe5aacf10001394064';

const TRUSTPILOT_TEMPLATES = {
  miniCarousel: '539ad0ffdec7e10e686debd7',
  fullSizeCarousel: '53aa8912dec7e10d38f59f36',
  grid: '539adbd6dec7e10e686debee',
}

interface TrustPilotProps extends FlexProps {
  template: keyof typeof TRUSTPILOT_TEMPLATES;
  heading?: string;
  height?: string;
  width?: string;
}

const Wrapper = styled(Flex)`
  max-width: 1200px;
  margin: 72px auto;
  padding: 16px;
`;

const Disclaimer = styled(Flex)<TrustPilotProps>`
  margin-left: 20px;
  margin-top: 8px;

  ${`@media ${device.lg}`} {
    margin-left: ${({ template }) => template === 'grid' ? '0' : '250px'};
    margin-top: ${({ template }) => template === 'grid' ? '8px' : '-100px'};
  }
`;

export const TrustPilot: FC<TrustPilotProps> = ({ template, heading, height = '250px', width = '100%', ...rest }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Wrapper column {...rest}>
      { heading && (
        <ResponsiveHeading marginBottom={72} variant={2} maxWidth={700}>
          {heading}
        </ResponsiveHeading>
      )}
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id={TRUSTPILOT_TEMPLATES[template]}
        data-businessunit-id={BUSINESS_UNIT_ID}
        data-style-height={height}
        data-style-width={width}
        data-theme="light"
        data-stars="1,2,3,4,5"
        data-review-languages="en"
        ref={ref}
      >
        <a
          href="https://www.trustpilot.com/review/retirable.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
      <Disclaimer template={template}>
        <Body variant={5}>
          These testimonials are provided by current or former clients of Retirable. Clients do not receive any compensation for sharing their opinions and experience with our firm. Comments and opinions expressed in these testimonials may not be representative of any other person's experience with our firm.
        </Body>
      </Disclaimer>
    </Wrapper>
  );
};
