import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Subheading } from 'component-library/components/typography/Subheading';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const Wrapper = styled.div`
  margin-bottom: 24px;
  padding-top: 120px;
`;

export const HeroLarge = () => (
  <Wrapper>
    <ResponsiveHeading variant={1} marginBottom={16}>
      {heading}
    </ResponsiveHeading>
    <Subheading variant={2} marginBottom={40}>
      {body}
    </Subheading>
  </Wrapper>
);
