import { FaqCategoryMenuQueryFragment } from 'graphqlTypes';
import { DropDownNavigationMenu } from 'page-components/shared/navigation-menus/DropDownNavigationMenu';
import React, { FC } from 'react';

interface FaqCategoryMenuSmallProps {
  data: FaqCategoryMenuQueryFragment;
  currentCategoryId?: string;
  onCategoryClick: (id: string) => void;
}

export const FaqCategoryMenuSmall: FC<FaqCategoryMenuSmallProps> = ({
  currentCategoryId,
  onCategoryClick,
  data,
}) => {
  const orderedCategories = data.allContentfulFaqCategory.nodes.map(
    (faqCategory) => ({
      id: faqCategory.id,
      heading: faqCategory.heading || '',
      isCurrentlySelected: faqCategory.id === currentCategoryId,
      onClick: () => onCategoryClick(faqCategory.id),
    }),
  );

  return (
    <DropDownNavigationMenu
      dropDownTitle="Categories"
      items={orderedCategories}
    />
  );
};
